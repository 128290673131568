<template>
  <div id="app" :style="cssProps">
    <div v-if="isMaintenanceMode">
      <maintenance :company="this.$store.state.companyDetails" />
    </div>
    <div v-else>
      <div v-if="this.$store.state.isLoading.value || !hasCompanyDetails" class="spinner-wrapper">
        <div class="sp sp-circle"></div>
        <p v-if="this.$store.state.loadingMessage" class="text-animation">{{ this.$store.state.loadingMessage }}</p>
      </div>
      <div id="main" v-if="hasCompanyDetails">
        <navbar id="nav" />
        <router-view />
        <general-footer />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/general/Navbar.vue'
import Maintenance from './components/general/Maintenance.vue'
import GeneralFooter from '@/components/general/Footer.vue'
import { mapActions } from 'vuex'
import Tawk from 'vue-tawk'
import Zendesk from '@dansmaculotte/vue-zendesk'
import Vue from 'vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import axios from 'axios'

export default {
  data () {
    return {
      isLoading: true,
      bodyBackgroundColor: '',
      bodyFontColor: '',
      redirectUrl: false,
      seo: {
        title: '',
        description: ''
      }
    }
  },
  metaInfo() {
    return {
      title: this.seo.site_title ? this.seo.site_title : this.$store.state.companyDetails.fantasyName,
      titleTemplate: '%s - Página inicial',
      meta: [
        { name: 'description', content: this.seo.description},
        { name: 'keywords', content: this.seo.keywords},
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    }
  },
  components: {
    Navbar,
    GeneralFooter,
    LottieAnimation,
    Maintenance
  },
  computed: {
    hasCompanyDetails () {
      return Object.keys(this.$store.state.companyDetails).length > 0
    },
    isMaintenanceMode () {
      var mode = this.hasCompanyDetails ? this.$store.state.companyDetails.whitelabel.maintenance_mode : false
      return mode > 0
    },
    cssProps () {
      return {
        '--default-color':  this.hasCompanyDetails ? this.$store.state.companyDetails.whitelabel.body_font_color : '#d0d0d0',
        '--detault-color-transparent': this.hasCompanyDetails ? this.$store.state.companyDetails.whitelabel.body_font_color + '40'  : '#d0d0d040',
      }
    }
  },
  methods: {
    ...mapActions('cart', ['clearCart']),
    setSelectedCompany(status){
      if(status){
        this.$store.commit('UPDATE_COMPANY_GROUP_STATUS', status)
      }
    },
    applyBudget (budget) {
      let $scope = this
      var additionSplit = budget.addition > 0 ? (parseFloat(budget.addition) / budget.items.length) : 0;

      budget.items.forEach((item) => {
        let tripData;
        if(item.type === 'customtrip'){
          let driverExpensesValue = item.trip.driverExpenses.driverExpensesValue ? item.trip.driverExpenses.driverExpensesValue : 0;
          tripData = {
            customRouter: item.itinerary,
            selectedVehicle: {
              ...item.vehicle,
              image: item.vehicle.thumbnail,
              title: item.vehicle.name
            },
            driverExpenses:{
              driverExpensesValue: driverExpensesValue,
              enable: driverExpensesValue != 0,
              payDriverExpenses: item.trip.driverExpenses.payDriverExpenses != 0 && item.trip.driverExpenses.payDriverExpenses != null ? 'paynow' : 'paylater'
            },
            type: item.type,
            quote_id: budget.id,
            metas: item.metas
          }
        }else{
          tripData = {
            selectedVehicle: {
              ...item.vehicle,
              image: item.vehicle.thumbnail,
              title: item.vehicle.name
            },
            type: item.type,
            origin: item.trip.origin,
            destination: item.trip.destination,
            tripDetails: item.trip,
            quote_id: budget.id,
            metas: item.metas
          }
        }

        if(additionSplit > 0){
          tripData.selectedVehicle.priceCents = parseFloat(tripData.selectedVehicle.priceCents) + additionSplit
        }

        tripData.quote_id = budget.id;
        $scope.$store.commit('cart/setCartItem', tripData)

      })

      this.$store.commit('cart/setQuoteID', budget.id)
      this.$store.commit('cart/setDiscount', budget.discount)
      this.$store.commit('cart/setAddition', budget.addition)
      this.$store.commit('cart/calculateCartTotal', { data: this.$store.state })
    },
    checkAvailabilityApply (budget) {
      this.$store.commit('SET_LOADING_STATE', { value: true, text: 'Verificando disponibilidade do orçamento' })
      axios({
        method: 'GET',
        url: process.env.API_URL + `quotes/${budget.id}/check-availability`
      }).then(async (response) => {
        this.$store.commit('SET_LOADING_STATE', { value: false })
        this.available = response.data.available.all
        if( response.data.is_finished ) {
          this.$router.push('/pedidos/' + response.data.order_id)
        }
        if (this.available) {
          this.$store.commit('cart/defineCartSource', response.data.source ? response.data.source :  'whitelabel');
          this.applyBudget(budget)
        } else {
          this.$swal({
            title: 'Consulte disponibilidade!',
            text: 'Este orçamento precisa ser fechado com um atendente.',
            showDenyButton: false,
            showConfirmButton: true,
            confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'error'
          })
          this.actionSelected = ''
          this.$router.push('/')
        }
      })
    },
    checkOrderStatus (order_id) {
      axios({
        method: 'POST',
        url: process.env.API_URL + `user/order/check-status`,
        data: {
          order_id: order_id
        }
      }).then(async (response) => {
        console.log(respose)
        return response.data.status

      })
    },
    applyCoupon (coupom) {
      axios
        .get(process.env.API_URL + 'get-coupon', {
          params: {
            name: coupom,
            cart_total: this.$store.state.cart.cartData.total
          }
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('cart/setCoupon', response.data)
          }
        })
        .catch((error) => { console.log(error) })
    },
    async executeAction (data) {
      switch (data.action) {
        case 'quote_to_order':
          await this.checkAvailabilityApply(data.quote[0])
          this.redirectUrl = '/checkout'
          break
        case 'my_account':
          await this.$router.push('/minha-conta')
          break
        case 'apply_discount':
          await this.applyCoupon(data.coupon)
          break;
        case 'accept_terms':
          var orderStatus = await this.checkOrderStatus(data.order_id);
          setTimeout(() => {
            this.$router.push('/aceitar-termos/' + data.order_id)
          }, 1400);
          break;
        default:
          this.$router.push('/minha-conta/orcamentos')
          break
      }
    },
    async loopThroughActions(actions) {

      if (!Array.isArray(actions)) {
        actions = [actions]
      }

      for (const action of actions) {
        await (async () => {
          this.executeAction(action)
        })();
      }
    },
    startApp: async function () {
      this.$http.get(process.env.API_URL + 'company')
        .then(async (response) => {
          this.seo = response.data.seo;
          this.$store.commit('SET_COMPANY_DETAILS', response.data)
          this.$store.commit('SET_LOADING_STATE', { value: false })
          this.seo = response.data.seo;

          var TawkToURL = this.getTawkToURL(response.data.whitelabel.chats.tawkto)
          await this.initTawkTo(TawkToURL)

          this.insertFavicon(response.data.whitelabel.favicon)
        })

      var query = this.getQueryParams(document.location.search)

      if (query && query.company) {
          this.setSelectedCompany(true)
      }

      if (query && query.clean_cart) {
        this.clearCart()
      }
      if (query && query.login_request) {
        var store = this.$store
        await this.$http.get(process.env.API_URL + 'login-request/' + query.login_request)
          .then(response => {
            if (response.data.access_token) {
              store.commit('auth/setUserData', response.data.customer)
              localStorage.setItem('authToken', response.data.access_token)
              delete query.login_request;
              this.clearCart();
              (async () => {
                await this.loopThroughActions(response.data.login_request.json_data);
                if(this.redirectUrl){
                  this.$router.push(this.redirectUrl)
                }else{
                  this.$router.push('/minha-conta/orcamentos')
                }
              })();
            } else {
              this.$swal({
                title: 'Parece que seu link expirou!',
                timer: 4500,
                text: 'Por favor, solicite um novo link de acesso.',
                showDenyButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
                confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
                iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
                icon: 'error'
              })
            }
          })
      }

      this.$store.commit('TOGGLE_RESET_FORM', false )
    },
    async insertFavicon (icon) {
      const favicon = document.querySelector("link[rel~='icon']")
      favicon.href = icon
    },
    loadTawkToScript (embedUrl) {
      return new Promise((resolve, reject) => {
        if (!embedUrl) {
          reject(new Error('No API key provided'))
          return
        }
        var Tawk_API = Tawk_API || {}
        var Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement('script')
          var s0 = document.getElementsByTagName('script')[0]
          s1.async = true
          s1.src = embedUrl
          s1.charset = 'UTF-8'
          s1.setAttribute('crossorigin', '*')
          s1.onload = resolve
          s1.onerror = reject
          s0.parentNode.insertBefore(s1, s0)
        })()
      })
    },
    async sayHello () {
      const timestamp = Date.now()
      const lastLogin = new Date(this.$store.state.lastLoginEvent)
      const actualTime = new Date(timestamp)

      const diffInHours = (actualTime.getTime() - lastLogin.getTime()) / (1000 * 60 * 60)

      if (this.$store.state.auth.userData && diffInHours > 0) {
        var component = this
        let userInfo = {}
        userInfo['mensagem'] = this.$store.state.auth.userData.name + ' acabou de acessar o site'
        userInfo['nome'] = this.$store.state.auth.userData.name
        userInfo['email'] = this.$store.state.auth.userData.email
        userInfo['telefone'] = this.$store.state.auth.userData.tel
        window.Tawk_API.addEvent('novo-acesso', userInfo, function (error) {
          if (error) {
          } else {
            let now = Date.now()
            component.$store.commit('LAST_LOGIN_EVENT', now)
          }
        })
      }
    },
    async initTawkTo (embedUrl) {
      try {
        await this.loadTawkToScript(embedUrl).then((response) => {
          // this.sayHello()
        })
      } catch (error) {
      }
    },
    async insertChatIntegration (chats) {
      if (chats.tawkto !== null) {
        Vue.use(Tawk, {
          tawkSrc: this.getTawkToURL(chats.tawkto)
        })
      } else if (chats.zendesk !== null) {
        Vue.use(Zendesk, {
          key: this.$store.state.companyDetails.whitelabel.chats.zendesk,
          settings: {
            webWidget: {
              color: {
                theme: '#78a300'
              }
            }
          }
        })
      }
    },
    getQueryParams (qs) {
      qs = qs.split('+').join(' ')

      var params = {}
      var tokens
      var re = /[?&]?([^=]+)=([^&]*)/g

      while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2])
      }
      return params
    },
    getTawkToURL (script) {
      if (!script) {
        return false
      }

      let response = script.replace(/"/g, "'")
      response = response.split("src='")
      response = response[1].split("';")
      return response[0]
    },
    loadScriptFromBackend() {
      const companyDetails = this.$store.state.companyDetails;
      if (companyDetails && companyDetails.whitelabel.custom_scripts) {
        const scriptContent = companyDetails.whitelabel.custom_scripts;
        const script = document.createElement('script');
        script.text = scriptContent;
        script.async = true;
        document.body.appendChild(script);
      }
    }
  },
  mounted() {
    if (process.env.NODE_ENV === 'production') {
      smartlook('init', '066f30561254856538b14bb78b1d2744a94a1118', { region: 'eu' });
    }
    this.loadScriptFromBackend()
  },
  async created () {
    this.$store.commit('TOGGLE_RESET_FORM', true )
    this.$store.commit('SET_LOADING_STATE', { value: true })
    await this.startApp()

    window.store = this.$store

    if (this.$store.state.cart == null) {
      this.clearCart()
    }

    // document.body.style.setProperty('--default-color', this.$store.state.companyDetails.whitelabel.body_font_color)
  }
}

</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100% !important;
  .sp-circle {
    border-color:var(--detault-color-transparent);
    border-top-color: var(--default-color);
  }
}

#home,
#trip-details {
  text-align: center;
}

#home .form-wrapper.masked:before {
  content: '';
  background: #0802145c;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  @media (max-width: 1024px) {
    background-color: #01000396;
  }
}

#nav {
  z-index: 99999;
  position: relative;
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#nav.not-logged-in .container .nav-item a span {
  margin: 0 5px;
}

#nav.not-logged-in .container .nav-item {
  margin-top: 10px;
}

#nav.not-logged-in .container {
  @media(max-width:1024px) {
    flex-direction: column;
  }
}

@media print {
  .no-print {
    display: none !important;
  }
}

.spinner-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.5s ease-in-out;

  h3 {
    font-size: 16px;
    font-weight: 300;
  }
}


/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {

  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d4f8ff;
  color: #d4f8ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 10px;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d4f8ff;
  color: #d4f8ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d4f8ff;
  color: #d4f8ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #88c5d2;
  }

  50%,
  100% {
    background-color: #d4f8ff;
  }
}

// animation

.text-animation {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #484848;
  font-weight: 400;
  padding: 20px 60px;
  background: #f9f9f9;
  border-radius: 0;
}
</style>
